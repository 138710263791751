import React, {Suspense, useEffect, useState} from 'react';
import {Canvas} from '@react-three/fiber';
import {Html, OrbitControls, useGLTF} from '@react-three/drei';
import {
    ActionIcon,
    Anchor,
    Badge,
    Blockquote,
    Box,
    Button,
    Card,
    Center,
    Container,
    Divider,
    Flex,
    Grid,
    Group,
    Highlight,
    Image,
    Loader,
    MantineProvider,
    NativeSelect,
    NumberInput,
    Paper,
    SegmentedControl,
    SemiCircleProgress,
    Space,
    Stack,
    Stepper,
    Tabs,
    Text,
    Tooltip,
    useMantineTheme
} from "@mantine/core";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import {IconInfoCircle, IconRosette} from "@tabler/icons-react";
import {Link as RouterLink} from "react-router-dom";

function Model({modelPath}) {
    const {scene} = useGLTF(modelPath);
    return <primitive object={scene}/>;
}

const PatternBuilderCreate = () => {
    const theme = useMantineTheme();

    const [items, setItems] = useState([])
    const [selectedItem, setSelectedItem] = useState(null);
    const [step, setStep] = useState(0);
    const [difficulty, setDifficulty] = useState(0);
    const [price, setPrice] = useState(0);
    const [activeDesignTab, setActiveDesignTab] = useState("");
    const [designTabs, setDesignTabs] = useState({})
    const [gaugeStitches, setGaugeStitches] = useState(1);
    const [gaugeRows, setGaugeRows] = useState(1);
    const [selectedStitch, setSelectedStitch] = useState(null)


    const fetchPatternBuilderItems = async () => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/pattern_builder/`,
            );
            if (response.ok) {
                const jsonData = await response.json();
                setItems(jsonData.results)
                handleItemClick(jsonData.results[0])
            } else {
                console.error("Request failed with status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    useEffect(() => {
        fetchPatternBuilderItems()
    }, []);

    const nextStep = () => setStep((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setStep((current) => (current > 0 ? current - 1 : current));

    const handleItemClick = (item) => {
        setSelectedItem(item)
        setActiveDesignTab(item.parts ? item.parts[0].name : "")
        const updatedDesignTabs = item.parts.reduce((acc, part) => {
                acc[part.name] = part.customisation_items ? part.customisation_items[0].name : "";
                return acc;
            },
            {}
        )
        setDesignTabs(updatedDesignTabs)
        setSelectedStitch(item.stitches[0])
        calculateDifficulty(item.difficulty, item.parts, updatedDesignTabs)
        calculatePrice(item.price, item.parts, updatedDesignTabs)
    }

    const calculateDifficulty = (baseDifficulty, parts, designTabs) => {
        let partsDifficulty = 0;

        for (const [partName, selectedItemName] of Object.entries(designTabs)) {
            const part = parts.find(p => p.name === partName);
            if (part) {
                const customisationItem = part.customisation_items.find(item => item.name === selectedItemName);
                if (customisationItem) {
                    partsDifficulty += customisationItem.difficulty;
                }
            }
        }
        setDifficulty(baseDifficulty + partsDifficulty)
    }

    const calculatePrice = (basePrice, parts, designTabs) => {
        let partsPrice = 0;

        for (const [partName, selectedItemName] of Object.entries(designTabs)) {
            const part = parts.find(p => p.name === partName);
            if (part) {
                const customisationItem = part.customisation_items.find(item => item.name === selectedItemName);
                if (customisationItem) {
                    partsPrice += Number(customisationItem.price);
                }
            }
        }
        setPrice(Number(basePrice) + partsPrice)
    }

    const updateDesignTabs = (key, value, part) => {
        const updatedDesignTabs = {...designTabs}
        updatedDesignTabs[key] = value
        setDesignTabs(updatedDesignTabs)
        calculateDifficulty(selectedItem.difficulty, selectedItem.parts, updatedDesignTabs)
        calculatePrice(selectedItem.price, selectedItem.parts, updatedDesignTabs)

    }

    const confirmPatternBuilder = () => {
        console.log("CONFIRMED!")
    }

    return (
        <React.Fragment>
            <Space mt={"10vh"}/>
            <Box h={"90vh"} style={{display: 'flex', flexDirection: 'column', width: "100%"}}>
                <Container>
                    <Space h={"md"}/>
                    <Stepper active={step} onStepClick={setStep}>
                        <Stepper.Step label="Select" description="Choose your item">
                        </Stepper.Step>
                        <Stepper.Step label="Design" description="Customise your item">
                        </Stepper.Step>
                        <Stepper.Step label="Measure" description="Make your measurements">
                        </Stepper.Step>
                        <Stepper.Step label="Summary" description="Confirm your details">
                        </Stepper.Step>
                        <Stepper.Completed>
                            Completed, your selection has been confirmed
                        </Stepper.Completed>
                    </Stepper>
                    <Divider my={"md"}/>
                </Container>
                <Stack style={{flex: 1, overflowY: 'auto'}}>

                    <Flex
                        direction={{base: 'column', sm: 'row'}}
                        gap={{base: 'sm', sm: 'lg'}}
                        justify={{sm: 'center'}}
                    >
                            <Paper w="100%" shadow="xl" p="xl" m="md" withBorder>
                            {step === 0 && <Grid justify="flex-start" align="start">

                                {items.map((item) => {
                                        return (
                                            <Grid.Col key={item.uuid} span={{xl: 3, lg: 4, md: 6, sm: 12}}>

                                                <Card
                                                    withBorder
                                                    shadow="sm"
                                                    radius="md"
                                                    onClick={() => handleItemClick(item)}
                                                    style={{
                                                        cursor: "pointer",
                                                        ...(selectedItem === item && {
                                                            borderColor: theme.colors.violet[8], // Apply custom border color when selected
                                                            boxShadow: `0 0 10px ${theme.colors.violet[8]}`, // Apply custom shadow when selected
                                                        }),
                                                    }}
                                                >
                                                    <Card.Section withBorder inheritPadding py="xs">
                                                        <Group justify="space-between">
                                                            <Text fw={500}>{item.name}</Text>
                                                            {item.is_premium &&
                                                                <Tooltip label="Premium Required">
                                                                    <ActionIcon component={RouterLink} to="/premium"
                                                                                size="lg">
                                                                        <IconRosette size={20}/>
                                                                    </ActionIcon>
                                                                </Tooltip>
                                                            }
                                                        </Group>
                                                    </Card.Section>

                                                    <Text mt="sm" c="dimmed" size="sm">
                                                        {item.description}
                                                    </Text>

                                                    <Card.Section mt="sm">
                                                        <Image src={item.thumbnail}/>
                                                    </Card.Section>
                                                    <Center>
                                                        <SemiCircleProgress
                                                            fillDirection="left-to-right"
                                                            orientation="up"
                                                            size={200}
                                                            thickness={20}
                                                            value={item.difficulty}
                                                            label="Difficulty"
                                                        />
                                                    </Center>
                                                </Card>
                                            </Grid.Col>
                                        )
                                    }
                                )}
                            </Grid>}
                            {step === 1 && <React.Fragment>
                                <Tabs
                                    value={activeDesignTab}
                                    onChange={setActiveDesignTab}
                                    color="violet"
                                    variant="pills"
                                    radius="lg"
                                >
                                    <Tabs.List grow>
                                        {selectedItem?.parts.map((part) => {
                                            return (
                                                <Tabs.Tab key={part.uuid} value={part.name}>
                                                    {part.name}
                                                </Tabs.Tab>
                                            )
                                        })}
                                    </Tabs.List>
                                    <Divider my={"md"}/>
                                    {selectedItem?.parts.map((part) => {
                                        return (
                                            <Tabs.Panel key={part.uuid} value={part.name}>
                                                <Center>
                                                    <SegmentedControl
                                                        orientation="vertical"
                                                        value={designTabs[part.name]}
                                                        onChange={(e) => updateDesignTabs(part.name, e, part)}
                                                        color={"violet"}
                                                        data={part.customisation_items.map((customisation_item) => {
                                                            return (
                                                                {
                                                                    label: (
                                                                        <Center style={{gap: 10}}>
                                                                            <span>{customisation_item.name}</span>
                                                                            {customisation_item.price !== "0.00" &&
                                                                                <span>€{customisation_item.price}</span>}
                                                                            {customisation_item.is_premium &&
                                                                                <Tooltip label="Premium Required">
                                                                                    <IconRosette size={20}/>
                                                                                </Tooltip>
                                                                            }
                                                                        </Center>
                                                                    ),
                                                                    value: customisation_item.name,
                                                                }
                                                            )
                                                        })}
                                                    />
                                                </Center>
                                            </Tabs.Panel>
                                        )
                                    })}
                                </Tabs>
                                <Divider my={"md"}/>
                                <Center>
                                    <SemiCircleProgress
                                        fillDirection="left-to-right"
                                        orientation="up"
                                        size={200}
                                        thickness={20}
                                        value={difficulty}
                                        label="Difficulty"
                                    />
                                </Center>
                            </React.Fragment>}
                            {step === 2 && <React.Fragment>
                                <Paper shadow="xl">
                                    <Blockquote icon={<IconInfoCircle/>} color={"violet"}>
                                        It's time to choose your stitch and make your custom measurements by creating a
                                        gauge swatch.
                                        Don't know what a gauge swatch is, or how to create it?
                                        Check <Anchor href="/tutorials/gauge-swatch" target="_blank"> this small
                                        tutorial out </Anchor> to learn more!
                                    </Blockquote>
                                </Paper>
                                <Divider my={"md"}/>
                                <NativeSelect
                                    label="Stitch"
                                    description="Select your stitch type"
                                    value={selectedStitch.name}
                                    onChange={(event) => setSelectedStitch(selectedItem.stitches.find(stitch => stitch.name === event.currentTarget.value))}
                                    data={selectedItem.stitches.map(stitch => stitch.name)}
                                />
                                <Divider my={"md"}/>


                                <Highlight
                                    ta="center"
                                    highlight={['gauge swatch', '5', '4', '12.5', '10'].concat(selectedItem.stitches.map(stitch => stitch.name))}
                                    highlightStyles={{
                                        backgroundImage:
                                            'linear-gradient(45deg, var(--mantine-color-violet-4), var(--mantine-color-violet-9))',
                                        fontWeight: 700,
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                    }}
                                >
                                    {`Please create a gauge swatch with the length of 5 inches (12.5 centimetres) made out of ${selectedStitch.name}, then measure the inner 4 inches (10 centimetres).`}
                                </Highlight>
                                <NumberInput
                                    label="Stitches"
                                    value={gaugeStitches}
                                    onChange={setGaugeStitches}
                                    allowNegative={false}
                                    clampBehavior="strict"
                                    min={1}
                                />
                                <NumberInput
                                    label="Rows"
                                    value={gaugeRows}
                                    onChange={setGaugeRows}
                                    allowNegative={false}
                                    clampBehavior="strict"
                                    min={1}
                                />
                            </React.Fragment>}
                            {step === 3 &&
                                <React.Fragment>
                                    <Text>Design</Text>
                                    {Object.values(designTabs).map((designTab) => {
                                        return (
                                            <Badge variant="light">
                                                {designTab}
                                            </Badge>
                                        )
                                    })}
                                    <Divider my={"md"}/>
                                    <Text>
                                        Stitch
                                    </Text>
                                    <Badge variant="light">
                                        {selectedStitch.name}
                                    </Badge>
                                    <Divider my={"md"}/>
                                    <Text>
                                        Gauge Swatch
                                    </Text>
                                    <Badge variant="light">
                                        {gaugeStitches} Stitches
                                    </Badge>
                                    <Badge variant="light">
                                        {gaugeRows} Rows
                                    </Badge>
                                    <Divider my={"md"}/>
                                    <Text>
                                        Price
                                    </Text>
                                    <Badge variant="light">
                                        €{price}
                                    </Badge>
                                    <Divider my={"md"}/>
                                    <Center>
                                        <SemiCircleProgress
                                            fillDirection="left-to-right"
                                            orientation="up"
                                            size={200}
                                            thickness={20}
                                            value={difficulty}
                                            label="Difficulty"
                                        />
                                    </Center>
                                    <Divider my={"md"}/>
                                    <Center>
                                        <Button onClick={confirmPatternBuilder}>Confirm</Button>
                                    </Center>
                                </React.Fragment>
                            }
                        </Paper>
                        {step !== 0 && <Container miw="50%" m="md">
                            <Paper h="100%" shadow="xl" withBorder>
                                <Canvas camera={{position: [0, 0, -5], fov: 50}}>
                                    <ambientLight intensity={4.0}/>
                                    <directionalLight position={[0, 0, -5]} intensity={2.0}/>
                                    {/* Load item's base model if it exists */}
                                    {selectedItem?.model &&
                                        <Suspense
                                            fallback={
                                                <Html center>
                                                    <MantineProvider>
                                                        <Loader/>
                                                    </MantineProvider>
                                                </Html>
                                            }
                                        >
                                            <Model modelPath={selectedItem.model}/>
                                        </Suspense>
                                    }
                                    {/* Load models for the item's parts if they exist */}
                                    {selectedItem?.parts.map(part => {
                                        const selectedCustomisationItem = part.customisation_items.find(
                                            customisation_item => customisation_item.name === designTabs[part.name]
                                        );

                                        return (selectedCustomisationItem?.model ?
                                            <Suspense
                                                key={selectedCustomisationItem.uuid}
                                                fallback={
                                                    <Html center>
                                                        <MantineProvider>
                                                            <Loader/>
                                                        </MantineProvider>
                                                    </Html>
                                                }
                                            >
                                                <Model modelPath={selectedCustomisationItem.model}/>
                                            </Suspense> : null)
                                    })}
                                    <OrbitControls/>
                                </Canvas>
                            </Paper>
                        </Container>}
                    </Flex>
                </Stack>
                <Group justify="center" m="md">
                    <Button disabled={step === 0} variant="default" onClick={prevStep}>Back</Button>
                    <Button disabled={step === 3} onClick={nextStep}>Next step</Button>
                </Group>
            </Box>
        </React.Fragment>
    );
}

export default PatternBuilderCreate;
