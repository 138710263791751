import {useMantineColorScheme} from "@mantine/core";

export const createCell = (x, y, color = "#242424") => {
    const cell = {
                x,
                y,
                key: `${y}-${x}`,
                color: color,
                highlighted: false,
                selected: false,
            }
    return cell;
}