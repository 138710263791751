import {
    ActionIcon,
    Center,
    ColorInput,
    Divider,
    rem,
    ScrollArea,
    SegmentedControl,
    Stack,
    Tooltip
} from "@mantine/core";
import {
    IconArrowBackUp,
    IconBrush,
    IconBucketDroplet,
    IconEraser,
    IconNumber1, IconNumber2, IconNumber3, IconNumber4, IconNumber5,
    IconRoute2,
} from "@tabler/icons-react";
import React from "react";

const PaintCanvas = (
    {
        paintMode,
        setPaintMode,
        selectedColor,
        setSelectedColor,
        handleUndo,
        undoQueue,
        colorPalette,
        paintSize,
        setPaintSize,
    }) => {

    return (
            <Stack>
                <Divider label="Tool" labelPosition="center"/>
                <Center>
                        <SegmentedControl
                            value={paintMode}
                            onChange={setPaintMode}
                            color="violet"
                            orientation="vertical"
                            data={[
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Paint">
                                                <IconBrush style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                        </Center>
                                    ),
                                    value: 'brush'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Line">
                                                <IconRoute2 style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                        </Center>
                                    ),
                                    value: 'line'
                                },
                                // ToDo: Move Erase Line within Line tool for UX
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Erase Line">
                                                <IconEraser style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                        </Center>
                                    ),
                                    value: 'erase'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Fill">
                                                <IconBucketDroplet style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                        </Center>
                                    ),
                                    value: 'fill'
                                },
                            ]}
                        />
                    </Center>
                <Center>
                    <Tooltip label="Undo">
                            <ActionIcon
                                disabled={undoQueue.length === 0}
                                onClick={(e) => {handleUndo()
                                }}
                            >
                                <IconArrowBackUp size={20}/>
                            </ActionIcon>
                        </Tooltip>
                </Center>

                <Divider label="Color" labelPosition="center"/>
                        <ColorInput
                            disallowInput
                            value={selectedColor}
                            onChange={setSelectedColor}
                            format="hex"
                            withEyeDropper={true}
                            swatches={colorPalette}
                        />
                {paintMode === "brush" &&
                    <>
                                <Divider label="Size" labelPosition="center"/>
                    <Center>
                        <SegmentedControl
                            value={paintSize}
                            onChange={setPaintSize}
                            color="violet"
                            orientation="vertical"
                            data={[
                                {
                                    label: (
                                        <Center>
                                            <IconNumber1 style={{width: rem(16), height: rem(16)}}/>
                                        </Center>
                                    ),
                                    value: '1'
                                },
                                {
                                    label: (
                                        <Center>
                                            <IconNumber2 style={{width: rem(16), height: rem(16)}}/>
                                        </Center>
                                    ),
                                    value: '2'
                                },
                                {
                                    label: (
                                        <Center>
                                            <IconNumber3 style={{width: rem(16), height: rem(16)}}/>
                                        </Center>
                                    ),
                                    value: '3'
                                },
                                {
                                    label: (
                                        <Center>
                                            <IconNumber4 style={{width: rem(16), height: rem(16)}}/>
                                        </Center>
                                    ),
                                    value: '4'
                                },
                                {
                                    label: (
                                        <Center>
                                            <IconNumber5 style={{width: rem(16), height: rem(16)}}/>
                                        </Center>
                                    ),
                                    value: '5'
                                },
                            ]}
                        />
                    </Center>
                                </>
                }
                </Stack>
    )

}

export default PaintCanvas;