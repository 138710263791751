import React, {useRef, useState} from 'react';
import PaintCanvas from "./PaintCanvas";
import CountCanvas from "./CountCanvas";
import DirectionCanvas from "./DirectionCanvas";
import SizeCanvas from "./SizeCanvas";
import {useDisclosure, useMediaQuery} from "@mantine/hooks";

const Toolbar = (
    {
        gridData,
        setGridData,
        gridWidth,
        gridHeight,
        setGridWidth,
        setGridHeight,
        selectedColor,
        setSelectedColor,
        paintMode,
        setPaintMode,
        lines,
        setLines,
        currentLine,
        setCurrentLine,
        currentFill,
        setCurrentFill,
        countCell,
        setCountCell,
        accordion,
        currentCells,
        setCurrentCells,
        handleUndo,
        undoQueue,
        rowDirection,
        setRowDirection,
        rowStarting,
        setRowStarting,
        rowClockwise,
        setRowClockwise,
        rowSwitching,
        setRowSwitching,
        currentRow,
        setCurrentRow,
        currentCell,
        setCurrentCell,
        colorPalette,
        paintSize,
        setPaintSize,
    }
) => {
    const rowDirectionRef = useRef(rowDirection);
    const rowStartingRef = useRef(rowStarting);
    const rowSwitchingRef = useRef(rowSwitching);
    const gridHeightRef = useRef(gridHeight);
    const gridWidthRef = useRef(gridWidth);

    const [rowRange, setRowRange] = useState([0, (gridHeight - 1)])
    const [cellRange, setCellRange] = useState([0, (gridWidth - 1)])
    const [exampleGridData, setExampleGridData] = useState(Array.from({length: 5}, (_, y) =>
        Array.from({length: 5}, (_, x) => ({
            x,
            y,
            key: `${y}-${x}`,
            color: "#ffffff",
            border: '',
        }))
    ))
    const [currentRowPattern, setCurrentRowPattern] = useState([])
    const [fullPatternModalOpened, { open, close }] = useDisclosure(false);
    const isMobile = useMediaQuery('(max-width: 50em)');
    const [fullPatternText, setFullPatternText] = useState('');

    return (
        <React.Fragment>
            {accordion === "paint" && <PaintCanvas
                        paintMode={paintMode}
                        setPaintMode={setPaintMode}
                        selectedColor={selectedColor}
                        setSelectedColor={setSelectedColor}
                        handleUndo={handleUndo}
                        undoQueue={undoQueue}
                        colorPalette={colorPalette}
                        paintSize={paintSize}
                        setPaintSize={setPaintSize}
                    />}
                    {accordion === "count" && <CountCanvas
                        accordion={accordion}
                        currentRow={currentRow}
                        setCurrentRow={setCurrentRow}
                        rowRange={rowRange}
                        setRowRange={setRowRange}
                        cellRange={cellRange}
                        setCellRange={setCellRange}
                        currentRowPattern={currentRowPattern}
                        setCurrentRowPattern={setCurrentRowPattern}
                        currentCells={currentCells}
                        setCurrentCells={setCurrentCells}
                        rowDirection={rowDirection}
                        gridData={gridData}
                        setGridData={setGridData}
                        gridHeight={gridHeight}
                        gridWidth={gridWidth}
                        rowStarting={rowStarting}
                        rowSwitching={rowSwitching}
                        rowClockwise={rowClockwise}
                        setRowClockwise={setRowClockwise}
                        currentCell={currentCell}
                        setCurrentCell={setCurrentCell}
                        countCell={countCell}
                        setCountCell={setCountCell}
                        fullPatternModalOpened={fullPatternModalOpened}
                        fullPatternModalOpen={open}
                        fullPatternModalClose={close}
                        isMobile={isMobile}
                        fullPatternText={fullPatternText}
                        setFullPatternText={setFullPatternText}
                        rowDirectionRef={rowDirectionRef}
                        rowStartingRef={rowStartingRef}
                        rowSwitchingRef={rowSwitchingRef}
                        gridHeightRef={gridHeightRef}
                        gridWidthRef={gridWidthRef}
                    />}
                    {accordion === "direction" && <DirectionCanvas
                        rowDirection={rowDirection}
                        setRowDirection={setRowDirection}
                        rowStarting={rowStarting}
                        setRowStarting={setRowStarting}
                        rowClockwise={rowClockwise}
                        setRowClockwise={setRowClockwise}
                        rowSwitching={rowSwitching}
                        setRowSwitching={setRowSwitching}
                    />}
                    {accordion === "size" && <SizeCanvas
                        gridData={gridData}
                        setGridData={setGridData}
                        gridHeight={gridHeight}
                        setGridHeight={setGridHeight}
                        gridWidth={gridWidth}
                        setGridWidth={setGridWidth}
                        lines={lines}
                        setLines={setLines}
                        currentLine={currentLine}
                        setCurrentLine={setCurrentLine}
                        currentFill={currentFill}
                        setCurrentFill={setCurrentFill}
                    />}
            </React.Fragment>
    );
};

export default Toolbar;
