import {Badge, Center, Group, rem, Slider} from "@mantine/core";
import {IconLetterX, IconLetterY, IconZoomFilled} from "@tabler/icons-react";
import React from "react";

const Scale = (
    {
        zoomDisplay,
        setZoomDisplay,
        viewport,
        setViewport,
        gridWidth,
        gridHeight,
        cellSize,
    }) => {

    return (
                    <Slider
                        value={zoomDisplay}
                        onChange={setZoomDisplay}
                        onChangeEnd={
                            (e) => {
                                const newScale = (((gridWidth + gridHeight) / 2) * (e/100) / (cellSize)) * (e/100)
                                setViewport({
                                    ...viewport,
                                    // ToDo: Temporary fix right now because canvas can't seem to load when it's over 25 scale
                                    scale: newScale > 25 ? 25 : newScale
                                })
                            }}
                        thumbChildren={<IconZoomFilled/>}
                        color="violet"
                        defaultValue={0}
                        thumbSize={25}
                        step={5}
                        styles={{
                            thumb: {borderWidth: rem(2), padding: rem(3)},
                            markLabel: {fontSize: rem(10)}
                        }}
                        label={(value) => `${value}%`}
                        marks={[
                            {value: 0, label: '0%'},
                            {value: 25, label: '25%'},
                            {value: 50, label: '50%'},
                            {value: 75, label: '75%'},
                            {value: 100, label: '100%'},
                        ]}
                    />
    )

}

export default Scale;